import Vue from 'vue';
import './layout.scss';
import layoutBase from '../layout-base.js';
import mvtu from '../../modules/MVTU/mvtu';
import videoPlayer from '../../common/components/video-player/video-player.vue';
import VueYouTubeEmbed from 'vue-youtube-embed';

Vue.use(VueYouTubeEmbed);

new Vue({ extends: layoutBase, components: { mvtu, videoPlayer } }).$mount('[data-layout-id="ANYIT-app"]');
